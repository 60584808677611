import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import Lightbox from "react-18-image-lightbox";
import "../../node_modules/react-18-image-lightbox/style.css";
import "./css/project-card.css";
import { Link } from "react-router-dom";

import Project1 from "../assets/images/project-images/100g-nutrition-image3.png";
import Project2 from "../assets/images/project-images/fcblog-image5.png";
import Project3 from "../assets/images/project-images/omni-food-image2.png";
import Project4 from "../assets/images/project-images/rmtdevfc-image2.png";
import Project5 from "../assets/images/project-images/firstclassdetailing-2.png";
import Project6 from "../assets/images/project-images/flex-craft-3.png";
import Project7 from "../assets/images/project-images/forever-classics-1.png";
import TestImage from "../assets/images/project-images/test-image.png";

// import Project2 from "../assets/images/project-images/fcblog-full-image1.png";
// import Project1 from "../assets/images/project-images/mario.png";

const images = [
  "/images/works/1.jpg",
  "/images/works/2.jpg",
  "/images/works/3.jpg",
  "/images/works/4.jpg",
  "/images/works/5.jpg",
  "/images/works/6.jpg",
  "/images/works/7.jpg",
  "/images/works/8.jpg",
];

export default function Projects() {
  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(!isOpen);
  };

  return (
    <>
      <section className="relative md:py-24 py-16" id="project">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">
              My Work & Projects
            </h3>

            {/* <p className="text-slate-400 max-w-xl mx-auto text-[15px]">
              Obviously I'm a Web Designer. Web Developer with over 7 years of
              experience. Experienced with all stages of the development.
            </p> */}
          </div>

          {/* ******** */}

          <div className="project-grid grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8">
            {/* Start of Card */}
            <Link2
              to="https://firstclassdetailing.fcsolutions.io/"
              target="_blank"
            >
              <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800 h-16">
                <div className="relative">
                  <img
                    src={Project5}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>

                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                <div className="absolute  bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                  <Link2
                    to="https://firstclassdetailing.fcsolutions.io/"
                    className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                    target="_blank"
                  >
                    Visit First Class Detailing
                  </Link2>

                  {/* <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2> */}

                  <span className="block text-sm text-slate-400 mt-0">
                    Wordpress, CSS, Responsive Design
                  </span>
                </div>

                <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                  {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
                </div>
              </div>
            </Link2>
            {/* End of Card */}

            {/* Start of Card */}
            <Link2 to="https://fcsolutions.io/" target="_blank">
              <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800 h-16">
                <div className="relative">
                  <img
                    src={Project6}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>

                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                <div className="absolute  bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                  <Link2
                    to="https://fcsolutions.io/"
                    className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                    target="_blank"
                  >
                    Visit Flex Craft
                  </Link2>

                  {/* <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2> */}

                  <span className="block text-sm text-slate-400 mt-0">
                    Wordpress, CSS, Responsive Design
                  </span>
                </div>

                <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                  {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
                </div>
              </div>
            </Link2>
            {/* End of Card */}

            {/* Start of Card */}
            <Link2 to="https://f936a2-e3.myshopify.com/" target="_blank">
              <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800 h-16">
                <div className="relative">
                  <img
                    src={Project7}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>

                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
                <div className="absolute  bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                  <Link2
                    to="https://f936a2-e3.myshopify.com/"
                    className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                    target="_blank"
                  >
                    Visit Forever Classics
                  </Link2>

                  {/* <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2> */}
                  <span className="block text-sm text-slate-400 mt-0">
                    PASSWORD: viewme
                  </span>
                  <span className="block text-sm text-slate-400 mt-0">
                    Shopify, CSS, Responsive Design
                  </span>
                </div>

                <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                  {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
                </div>
              </div>
            </Link2>
            {/* End of Card */}

            {/* Start of Card */}
            {/* UNCOMMENTING: IT HAS COMMENTS IN IT SO ITS WEIRD TO UNCOMMENT, BUT YOU HAVE 2 place to UNCOMMENT, up to link2 and div end after */}
            {/* <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800 h-16">
              <div className="relative">
                <img
                  src={Project1}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>

              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
              <div className="absolute  bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                <Link2
                  to="https://100gnutrition.netlify.app"
                  className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                  target="_blank"
                >
                  Visit 100g Nutrition
                </Link2>

                <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2>

                <span className="block text-sm text-slate-400 mt-6">
                  Vite + React, Tailwind, TypeScript, React Router
                </span>
              </div>

              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500"> */}
            {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
            {/* </div>
            </div>  */}
            {/* End of Card */}

            {/* Start of Card */}
            {/* <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
              <div className="relative">
                <img
                  src={Project2}
                  alt=""
                  className="h-full w-full object-fill"
                />
              </div>

              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
              <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500 ">
                <Link2
                  to="https://fcblog.netlify.app/"
                  className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                  block
                  target="_blank"
                >
                  Visit FC Blog
                </Link2>

                <Link2
                  to="https://github.com/FarazChand/blog-project"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2>

                <span className="block text-sm text-slate-400 mt-6">
                  React, Sass, SanityCMS
                </span>
              </div>

              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500"> */}
            {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
            {/* </div>
            </div> */}
            {/* End of Card */}

            {/* Start of Card */}
            {/* <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
              <div className="relative">
                <img
                  src={Project3}
                  alt=""
                  className="h-full w-full object-fill"
                />
              </div>

              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
              <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                <Link2
                  to="https://omnifood-fc.netlify.app/"
                  className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                  block
                  target="_blank"
                >
                  Omnifood
                </Link2>
                <Link2
                  to="https://github.com/FarazChand/Udemy_HTML_CSS/tree/main/Projects/09-Omnifood-Optimizations"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2>

                <span className="block text-sm text-slate-400 mt-6">
                  HTML, CSS, UI / UX Design,
                </span>
              </div>

              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500"> */}
            {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
            {/* </div>
            </div> */}
            {/* End of Card */}

            {/* Start of Card */}
            {/* <div className="project-card relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
              <div className="relative">
                <img
                  src={Project4}
                  alt=""
                  className="h-full w-full object-fill"
                />
              </div>

              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
              <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                <Link2
                  to="https://rmtdevfc.netlify.app"
                  className="text-white hover:text-amber-500 font-semibold transition-all duration-500 block"
                  block
                  target="_blank"
                >
                  RmtDevFc
                </Link2>
                <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2>

                <span className="block text-sm text-slate-400 mt-6">
                  Vite + React, TypeScript, CSS
                </span>
              </div>

              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500"> */}
            {/* <Link2 to="#" onClick={() => handleCLick(0)} className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"><Unicons.UilCamera width={16}/></Link2> */}
            {/* </div>
            </div> */}
            {/* End of Card */}

            {/* <div className="relative group overflow-hidden rounded-lg shadow shadow-slate-200 dark:shadow-gray-800">
              <img src="/images/works/8.jpg" alt="" />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-b to-slate-900 from-transparent transition-all duration-500"></div>
              <div className="absolute bottom-0 opacity-0 group-hover:opacity-100 m-6 transition-all duration-500">
                <Link2
                  to="#!"
                  className="text-white hover:text-amber-500 font-semibold transition-all duration-500"block
                >
                  Techwind Theme
                
                        <Link2
                  to="https://github.com/FarazChand/100g-nutrition-website"
                  target="_blank"
                  className="inline-block "
                >
                  <span className="block text-md hover:text-amber-500 text-slate-400">
                    View on GitHub
                  </span>
                </Link2>
                  </Link2>
                <span className="block text-sm text-slate-400 mt-6">
                  Multipurpose
                </span>
              </div>

              <div className="absolute z-10 opacity-0 group-hover:opacity-100 top-1/2 -translate-y-1/2 right-0 left-0 mx-auto text-center transition-all duration-500">
                <Link2
                  to="#"
                  onClick={() => handleCLick(7)}
                  className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white btn-icon rounded-full lightbox"
                >
                  <Unicons.UilCamera width={16} />
                </Link2>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}
