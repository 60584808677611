import * as Icon from "react-feather";
import cssImg from "../assets/images/bg/css.png";
import htmlImg from "../assets/images/bg/html.png";
import jsImg from "../assets/images/bg/javascript.png";
import reactImg from "../assets/images/bg/react.png";
import gitImg from "../assets/images/bg/git.png";
import tsImg from "../assets/images/bg/typescript.png";
import nodeImg from "../assets/images/bg/node.png";
import mobileImg from "../assets/images/bg/mobile.png";
import reduxImg from "../assets/images/bg/redux.png";
import wordpressImg from "../assets/images/bg/wordpress-plain.svg";
import shopifyImg from "../assets/images/bg/shopify.svg";

export const expertiseData = [
  {
    title: "HTML",
    Icon: htmlImg,
  },
  {
    title: "CSS",
    Icon: cssImg,
  },

  {
    title: "JavaScript",
    Icon: jsImg,
  },
  {
    title: "React",
    Icon: reactImg,
  },
  {
    title: "Git",
    Icon: gitImg,
  },
  {
    title: "TypeScript",
    Icon: tsImg,
  },
  {
    title: "WordPress",
    Icon: wordpressImg,
  },
  {
    title: "Shopify",
    Icon: shopifyImg,
  },
  {
    title: "Responsive Design",
    Icon: mobileImg,
  },
];
export const servicesData = [
  {
    title: "UX / UI Design",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Airplay,
  },
  {
    title: "Ios App Designer",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Aperture,
  },
  {
    title: "Photography",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Camera,
  },
  {
    title: "Graphic Designer",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Compass,
  },
  {
    title: "Web Security",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Settings,
  },
  {
    title: "Web Security",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
    Icon: Icon.Watch,
  },
];
export const project = [
  {
    image: "/images/works/1.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/2.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/3.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/4.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/5.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/6.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/7.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
  {
    image: "/images/works/8.jpg",
    title: "Fronter Theme",
    subTitle: "UI / UX Design",
  },
];
export const blogData = [
  {
    image: "/images/blog/01_giglink.jpg",
    title: "Giglink: Tailwind CSS NFT Marketplace Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_techwind.jpg",
    title: "Techwind: Tailwind CSS Multipurpose Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
  {
    image: "/images/blog/01_upwind.jpg",
    title: "Upwind: Tailwind CSS Landing Page Template",
    desc: "The phrasal sequence of the is now so that many campaign and benefit",
  },
];
export const review = [
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
  {
    name: "Thomas Israel",
    designation: "Manager",
    image: "/images/client/01.jpg",
    desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development.",
  },
];
